import React, { useState, useEffect } from 'react'; 
import { Modal, Button, Table } from 'react-bootstrap';
import './ShowOrderDetailsAPI.css';
import apiClient from "../../../../../../../api/apiClient";
import { formatDate } from "../../../../../../../utils/dates";

function OrderDetailsModal({ show, handleClose, idOrder }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (show) {
            const fetchData = async () => {
                try {
                    setLoading(true); // Reiniciar el estado de loading cuando se vuelve a abrir el modal
                    const response = await apiClient.get(`/orderDetailsByDEPTransaction/${idOrder}`);
                    setData(response.data);
                    setLoading(false);
                } catch (err) {
                    setError("Error al obtener los detalles de la orden");
                    setLoading(false);
                }
            };
            fetchData();
        } else {
            // Limpiar los datos y estados cuando se cierra el modal
            setData(null);
            setLoading(true);
            setError(null);
        }
    }, [show, idOrder]);

    // Función para cerrar el modal y limpiar la información
    const handleModalClose = () => {
        setData(null); // Limpiar los datos
        setLoading(true); // Reiniciar loading a true para que no se muestren los datos previos
        handleClose();  // Cerrar el modal
    };

    if (loading) {
        return (
            <Modal show={show} onHide={handleModalClose} size="lg" aria-labelledby="modalTitle" centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title id="modalTitle" style={{ color: '#000' }}>
                        Cargando detalles de la orden...
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Muestra un spinner o cualquier indicador de carga */}
                    <div>Cargando...</div>
                </Modal.Body>
            </Modal>
        );
    }

    if (error || (data && data.showOrderErrorResponse)) {
        return (
            <Modal show={show} onHide={handleModalClose} size="lg" aria-labelledby="modalTitle" centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title id="modalTitle" style={{ color: '#000' }}>
                        {error ? 'Error al cargar los detalles de la orden' : 'Error en la respuesta de la API'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error ? (
                        error
                    ) : (
                        <div>
                            <p>{data.showOrderErrorResponse[0].errorMessage}</p>
                            <p><strong>Código de error:</strong> {data.showOrderErrorResponse[0].errorCode}</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    if (!data || !data.orders || !data.orders.length) {
        return (
            <Modal show={show} onHide={handleModalClose} size="lg" aria-labelledby="modalTitle" centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title id="modalTitle" style={{ color: '#000' }}>
                        No se encontraron detalles de la orden
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>No hay detalles disponibles para la orden solicitada.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleModalClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <Modal show={show} onHide={handleModalClose} size="lg" aria-labelledby="modalTitle" centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title id="modalTitle" style={{ color: '#000' }}>
                    Detalles de la orden {data.orders[0].orderNumber} (API)
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {data && (
                    <>
                        <Table id="orderTableModal" className="table">
                            <thead>
                                <tr>
                                    <th>Fecha de Orden</th>
                                    <th>Tipo de Orden</th>
                                    <th>ID de Cliente</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{formatDate(data.orders[0].orderDate)}</td>
                                    <td>{data.orders[0].orderType}</td>
                                    <td>{data.orders[0].customerId}</td>
                                    <td>{data.statusCode}</td>
                                </tr>
                            </tbody>
                        </Table>

                        {data.orders[0].deliveries && data.orders[0].deliveries[0].devices && (
                            <Table id="orderTableModal" className="table">
                                <thead>
                                    <tr>
                                        <th>Dispositivo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.orders[0].deliveries[0].devices.map((device) => (
                                        <tr key={device.deviceId}>
                                            <td>{device.deviceId}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleModalClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default OrderDetailsModal;
