import React, { useState, useEffect } from "react";
import { useHeader } from "../../../../contexts/HeaderContext";
import Table from "../../../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import DataResellerVerification from "../../../shared/pages/DataResellerVerification/DataResellerVerification";
import { useAuth } from '../../../../contexts/AuthProvider';
import apiClient from "../../../../api/apiClient";
import { formatDate } from "../../../../utils/dates";
import Swal from 'sweetalert2';

function AwaitingToEnroll() {
    const { setHeaderTitle } = useHeader();
    const [selectedRows, setSelectedRows] = useState([]);
    const [company, setCompany] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [canEnroll, setCanEnroll] = useState(false);
    const [pendingAuth, setPendingAuth] = useState(false);
    const [pathPrint, setPathPrint] = useState(null);
    const navigate = useNavigate();
    const { userInfo } = useAuth();
    const { read_level } = userInfo.role || {};

    useEffect(() => {
        setHeaderTitle("Enrolamientos");
    }, [setHeaderTitle]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const dataCompany = await apiClient.get(`/companies/${userInfo.company.id}`);
                setCanEnroll(dataCompany.data.can_enroll);
                setPendingAuth(dataCompany.data.pending_auth);
                setPathPrint(dataCompany.data.path_print);
                setCompany(dataCompany.data);

                if (dataCompany.data.can_enroll || read_level === "2") {
                    const response = await apiClient.get("/find-sales-record-pending-enrolled");
                    setData(response.data);
                }
            } catch (err) {
                setError("Error al obtener los datos");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [userInfo.company.id, read_level]);

    const headers = [
        { label: "Fecha compra", accessor: "date", searchable: "true" },
        { label: "Revendedor", accessor: "company_name", searchable: "true" },
        { label: "Apple Reseller ID", accessor: "apple_reseller_id", searchable: "true" },
        { label: "Nº Factura Startech", accessor: "invoice_number", searchable: "true" },
        { label: "Estado", accessor: "enrolled_count", searchable: "true" },
    ];

    const handleSelectedRowsChange = (newSelectedRows) => {
        setSelectedRows(newSelectedRows);

        if (newSelectedRows.length > 0) {
            // Validar si todas las filas seleccionadas tienen un `apple_reseller_id` válido
            const allHaveResellerId = newSelectedRows.every(row => row.apple_reseller_id && row.apple_reseller_id.trim() !== '');

            if (!allHaveResellerId) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Selección inválida',
                    text: 'Todas las facturas seleccionadas deben tener un Apple Reseller ID válido.',
                    confirmButtonText: 'Entendido'
                });
            }
        }

        // Verificar si todas las filas tienen el mismo `apple_reseller_id`
        if (newSelectedRows.length > 1) {
            const currentResellerId = newSelectedRows[0].apple_reseller_id;
            const validSelection = newSelectedRows.every(row => row.apple_reseller_id === currentResellerId);

            if (!validSelection) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Selección no consistente',
                    text: 'Ha seleccionado facturas de diferentes revendedores. Solo puede solicitar enrolamientos de facturas del mismo revendedor.',
                    confirmButtonText: 'Entendido'
                });
            }
        }
    };

    const handleRequestEnrollment = () => {
        if (selectedRows.length > 0) {
            const allHaveResellerId = selectedRows.every(row => row.apple_reseller_id && row.apple_reseller_id.trim() !== '');

            if (!allHaveResellerId) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Selección inválida',
                    text: 'Todas las facturas seleccionadas deben tener un Apple Reseller ID antes de solicitar el enrolamiento.',
                    confirmButtonText: 'Entendido'
                });
                return;
            }

            const currentResellerId = selectedRows[0].apple_reseller_id;
            const validSelection = selectedRows.every(row => row.apple_reseller_id === currentResellerId);

            if (!validSelection) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Selección no permitida',
                    text: 'Solo se pueden solicitar enrolamientos de facturas del mismo revendedor.',
                    confirmButtonText: 'Entendido'
                });
                return;
            }

            const selectedIds = selectedRows.map(row => row.id);
            const queryString = `?selectedIds=${selectedIds.join(',')}`;
            navigate(`/enrollments/new${queryString}`);
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Ninguna factura seleccionada',
                text: 'Por favor, seleccione al menos una factura para enrolar.',
                confirmButtonText: 'Entendido'
            });
        }
    };

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div className="alert alert-danger mt-3">{error}</div>;
    }

    if (canEnroll || read_level === "2") {
        return (
            <div>
                <div className="row layout-top-spacing">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                        <div className="widget widget-content-area br-4">
                            <div className="widget-one">
                                <h5 className="widget-title">Sin enrolar</h5>
                                <Table
                                    data={data.map(order => ({
                                        ...order,
                                        date: formatDate(order.date),
                                        enrolled_count: order.enrolled_count === 0 ? "Sin enrolar" : "Enrolamiento parcial",
                                    }))}
                                    headers={headers}
                                    initialRowsPerPage={10}
                                    enableRowClick={userInfo.role.execute}
                                    onSelectedRowsChange={handleSelectedRowsChange}
                                    selectedRows={selectedRows}
                                    dataName={"facturas para enrolar"}
                                    enableCheckbox={userInfo.role.execute}
                                />
                                {userInfo.role.execute && (
                                    <button
                                        className="btn btn-primary mt-5"
                                        onClick={handleRequestEnrollment}
                                    >
                                        Solicitar enrolamiento
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (pendingAuth) {
        return (
            <div className="alert alert-info mt-3">
                Su información fue recibida y está siendo validada. Intente más tarde.
            </div>
        );
    } else if (pathPrint) {
        return (
            <div>
                <div className="alert alert-danger mt-3">
                    Su validación fue rechazada. Debe subir un nuevo screenshot.
                </div>
                <DataResellerVerification company={company} />
            </div>
        );
    } else {
        return <DataResellerVerification company={company} />;
    }
}

export default AwaitingToEnroll;
