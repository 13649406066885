import React, { useEffect, useState } from "react";
import "../../../../assets/css/apps/invoice-preview.css";
import './Order.css';
import { useHeader } from "../../../../contexts/HeaderContext";
import apiClient from "../../../../api/apiClient";
import { useParams } from 'react-router-dom';
import { formatDate } from "../../../../utils/dates";
import Table from "../../../../components/Table/Table";
import { Form, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2'; // Importar SweetAlert
import { useNavigate } from "react-router-dom";

const OrderReturn = () => {
    const { id } = useParams();
    const { setHeaderTitle } = useHeader();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();

    // State to manage form inputs
    const [formData, setFormData] = useState({
        delivery_date: '',
        delivery_number: '',
        return_date: '',
    });

    useEffect(() => {
        setHeaderTitle("Orden de retorno");
    }, [setHeaderTitle]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get(`/enrollment-full-detail/${id}`);
                setData(response.data);
                setLoading(false);
            } catch (err) {
                setError("Error al obtener las órdenes");
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const validateForm = () => {
        const errors = {};
        if (selectedDevices.length === 0) {
            errors.devices = "Debe seleccionar al menos un dispositivo para retornar.";
        }
        if (!formData.return_date) {
            errors.return_date_date = "Debe ingresar la fecha de devolución.";
        }
        if (!formData.delivery_date) {
            errors.delivery_date = "Debe ingresar la fecha de devolución.";
        }
        if (!formData.delivery_number) {
            errors.delivery_number = "Debe ingresar el número de envío.";
        }

        if (formData.delivery_date && data.server_current_date && new Date(formData.delivery_date) > new Date(data.server_current_date)) {
            errors.delivery_date = "La fecha de envío no puede ser mayor a la fecha actual.";
        }

        if (formData.return_date && data.server_current_date && new Date(formData.return_date) > new Date(data.server_current_date)) {
            errors.return_date = "La fecha de devolución no puede ser mayor a la fecha actual.";
        }

        if (formData.return_date && formData.delivery_date && new Date(formData.delivery_date) < new Date(formData.return_date)) {
            errors.delivery_date = "La fecha de envío debe ser igual o posterior a la fecha de devolución.";
        }

        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return errors;
        }
        return null;
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = async () => {
        const errors = validateForm();
        if (errors) return;

        try {
            await apiClient.post(`/unenroll-enrollment-device/${id}`, {
                ids_devices: selectedDevices.map(row => row.id),
                date_return: formData.return_date,
                date_delivery_return: formData.delivery_date,
                delivery_number_return: formData.delivery_number
            });
            Swal.fire({
                icon: 'success',
                title: 'Solicitud enviada',
                text: 'La solicitud de retorno ha sido enviada correctamente.',
            });
            navigate(`/enrollments/${id}`);
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un error al enviar la solicitud. Intente nuevamente.',
            });
        }
    };

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const fieldNames = {
        customer_name: "Razón social del cliente final",
        apple_organization_id: "Apple Organization ID del cliente final",
        date: "Fecha de la venta",
        reseller_invoice_number: "Número de comprobante de venta",
        return_date: "Fecha de devolución",
        delivery_date: "Fecha de envío",
        delivery_number: "Número de envío",
        selectedDevices: "Selección de dispositivos",
    };

    const headers = [
        { label: "Retornar", accessor: "enroled", searchable: false },
        { label: "SKU", accessor: "sku", searchable: true },
        { label: "Artículo", accessor: "description", searchable: true },
        { label: "Serie", accessor: "serial", searchable: true },
    ];

    return (
        <div>
            {/* Table and Information Section */}
            <div className="row invoice layout-top-spacing layout-spacing p-0">
                <div className="col-xl-12">
                    <div className="doc-container">
                        <div className="row">
                            <div className="col-xl-9">
                                <div className="invoice-container">
                                    <div className="invoice-inbox">
                                        <div id="ct">
                                            <div className="invoice-00001">
                                                <div className="content-section">
                                                    <div className="inv--head-section inv--detail-section"
                                                        style={{ margin: 0, borderBottom: "none", paddingTop: "21px" }}>
                                                        <div className="row">
                                                            <h5 className="p-0">Retornar dispositivos enrolados (RE)</h5>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-7 text-sm-left" style={{ paddingLeft: 0 }}>
                                                                <p className="inv-list-number">
                                                                    <span className="inv-title" style={{ color: "#000" }}>
                                                                        {data.customer_name}
                                                                    </span>
                                                                </p>
                                                                <p className="inv-email-address customer_font_size">
                                                                    Apple Organization ID {data.apple_organization_id}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-5 text-sm-end" style={{ paddingRight: "0px" }}>
                                                                <p className="inv-list-number">
                                                                    <span className="inv-title" style={{ color: "#000" }}>
                                                                        Orden Nº {data.reseller_invoice_number}
                                                                    </span>
                                                                </p>
                                                                <p className="inv-list-number">
                                                                    <span className="inv-title">
                                                                        {formatDate(data.date)}
                                                                    </span>
                                                                </p>
                                                                <p className="inv-list-number">
                                                                    <span className="inv-title">
                                                                        Fecha de envío: {formatDate(data.delivery_date)}
                                                                    </span>
                                                                </p>
                                                                <p className="inv-list-number">
                                                                    <span className="inv-title">
                                                                        Número de envío: {data.delivery_number}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginTop: "5px", marginBottom: "10px" }} />
                                                        <div className="row">
                                                            <div className="col-12 p-0">
                                                                <p>Seleccione los dispositivos enrolados que desea desenrolar y retornar</p>
                                                            </div>
                                                            <Table
                                                                data={data.devices}
                                                                headers={headers}
                                                                enableCheckbox={true}
                                                                initialRowsPerPage={10}
                                                                onSelectedRowsChange={setSelectedDevices}
                                                                selectedRows={selectedDevices}
                                                                dataName={"dispositivos"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Form Fields for Delivery Date and Number */}
            <div className="row layout-top-spacing">
                <div className="col-lg-9 col-xl-9 col-xxl-9  col-12">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <Row>
                                <div className="col-12 mb-3">
                                    <p>Indique la fecha de devolución y el número de envío asociado</p>
                                </div>
                                <Col md={4}>
                                    <Form.Group controlId="return_date">
                                        <Form.Label>Fecha de devolución</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={formData.return_date}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {formErrors.return_date && (
                                            <small className="text-danger">{formErrors.return_date}</small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="delivery_date">
                                        <Form.Label>Fecha de envío</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={formData.delivery_date}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {formErrors.delivery_date && (
                                            <small className="text-danger">{formErrors.delivery_date}</small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="delivery_number">
                                        <Form.Label>Número de envío</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={formData.delivery_number}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {formErrors.delivery_number && (
                                            <small className="text-danger">{formErrors.delivery_number}</small>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>

            {/* Submission Section */}
            <div className="row layout-top-spacing">
                <div className="col-lg-9 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <h5 className="widget-title">Enviar solicitud de retorno</h5>
                            <p>
                                Verifique los datos ingresados y presione el botón "Solicitar
                                retorno" para comenzar el proceso de desenrolamiento
                            </p>
                            <button className="btn btn-primary" onClick={handleSubmit}>
                                Solicitar retorno
                            </button>
                            {Object.keys(formErrors).length > 0 && (
                                <div className="mt-4 alert alert-danger">
                                    <ul>
                                        {Object.entries(formErrors).map(([key, error]) => (
                                            <li key={key}>{`${fieldNames[key]} - ${error}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderReturn;
