// Badge.js
import React from "react";
import { getStatusText, getBadgeClass } from "../../utils/status";

const Badge = ({ status }) => {
    const badgeClass = getBadgeClass(status); // Asignamos la clase CSS correspondiente
    const statusText = getStatusText(status); // Obtenemos el texto correspondiente

    return (
        <span className={badgeClass} style={{ transform: "none", boxShadow: "none" }}>
            {statusText}
        </span>
    );
};

export default Badge;
