// utils.js
export const getBadgeClass = (status) => {
  switch (status) {
    case "0":
    case 0:
      return "badge badge-info"; // En progreso
    case "1":
    case 1:
      return "badge badge-success"; // Completado
    case "2":
    case 2:
      return "badge badge-danger"; // Con errores
    case "3":
    case 3:
      return "badge badge-danger"; // Erróneo
    case "COMPLETE":
      return "badge badge-success"; // Completado
    case null:
    case undefined:
      return "badge badge-info"; // En progreso por defecto
    default:
      return "badge badge-secondary"; // Estado no identificado
  }
};

export const getStatusText = (status) => {
  switch (status) {
    case "0":
    case 0:
      return "En progreso";
    case "1":
    case 1:
      return "Completado";
    case "2":
    case 2:
      return "Con errores";
    case "3":
    case 3:
      return "Erróneo";
    case null:
    case undefined:
      return "En progreso";
    case "COMPLETE":
      return "Completado";
    default:
      return "Estado no identificado";
  }
};
