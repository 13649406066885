import React, { useEffect, useState } from "react";
import { useHeader } from "../../../../contexts/HeaderContext";
import "./AllOrders.css";
import Table from "../../../../components/Table/Table";
import apiClient from "../../../../api/apiClient";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../../contexts/AuthProvider';

function EnrollmentsErroneous() {
  const navigate = useNavigate();
  const { setHeaderTitle } = useHeader();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userInfo } = useAuth();
  const { read_level } = userInfo.role || {};

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("es-ES", options);
  };

  useEffect(() => {
    setHeaderTitle("Enrolamientos");

    const fetchData = async () => {
      try {
        const response = await apiClient.get("/list-enrollments-status", {
          params: {
            filter: { "enrollments.status": "3" },
          },
        });
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error al obtener las órdenes");
        setLoading(false);
      }
    };

    fetchData();
  }, [setHeaderTitle]);

  const headers = [
    { label: "Fecha enrolamiento", accessor: "created_at", searchable: true },
    ...(read_level === '2'
      ? [
        {
          label: "Revendedor",
          accessor: "company_name",
          searchable: true,
        },
      ]
      : []),
    {
      label: "N° Factura del reseller",
      accessor: "reseller_invoice_number",
      searchable: "true",
    },
    { label: "Cliente", accessor: "customer_name", searchable: "true" },
    {
      label: "Organization ID",
      accessor: "apple_organization_id",
      searchable: "true",
    },
    {
      label: "Tipo",
      accessor: "transaction_type",
      searchable: "true",
    },
    { label: "DEP Transaction ID", accessor: "dep_transaction_id", searchable: true },
    {
      label: "Error",
      accessor: "dep_transaction_message",
      searchable: "true",
    },
  ];

  if (loading) {
    return <div>Cargando órdenes...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handleRowClick = (row) => {
    navigate(`/enrollments/${row.id}`);
  };

  return (
    <div>
      <div className="row layout-top-spacing">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
          <div className="widget widget-content-area br-4">
            <div className="widget-one">
              <h5 className="widget-title">Enrolamientos Erróneos</h5>
              <Table
                data={data.map((order) => ({
                  ...order,
                  created_at: formatDate(order.created_at), // Formateo de la fecha
                }))}
                headers={headers}
                initialRowsPerPage={10}
                dataName={"órdenes"}
                enableRowClick={true}
                onRowClick={handleRowClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnrollmentsErroneous;
