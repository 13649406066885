import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHeader } from "../../../../contexts/HeaderContext";
import apiClient from "../../../../api/apiClient";
import Swal from 'sweetalert2';

const EditReseller = () => {
    const { setHeaderTitle } = useHeader();
    useEffect(() => {
        setHeaderTitle("Gestión de revendedores");
    }, [setHeaderTitle]);

    const { id } = useParams();

    const [formData, setFormData] = useState({
        erpId: '',
        name: '',
        apple_reseller_id: '',
        allowEnroll: false,
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get(`/companies/${id}`);
                setFormData({
                    erpId: response.data.erp_id,
                    name: response.data.name,
                    apple_reseller_id: response.data.apple_reseller_id,
                    can_enroll: response.data.can_enroll,
                });
                setLoading(false);
            } catch (err) {
                setError("Error al obtener las órdenes");
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiClient.put(`/companies/${id}`, formData);
            Swal.fire({
                icon: 'success',
                title: 'Revendedor actualizado',
                showConfirmButton: false,
                timer: 2000,    
            });
        } catch (error) {
            console.error('Error actualizando reseller:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="row layout-top-spacing">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <div className="col-xl-8 col-lg-9 col-md-12 col-12" style={{ paddingLeft: 0, marginBottom: 10 }}>
                                <h5>Editar revendedor</h5>
                                <br />
                                <form id="user_form" onSubmit={handleSubmit} autoComplete="off">
                                    <div className="form-group mb-4">
                                        <label className="bluelabel" htmlFor="erpId">Identificador ERP</label>
                                        <div>{formData.erpId}</div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="bluelabel" htmlFor="name">Razón social</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="bluelabel" htmlFor="apple_reseller_id">Apple Reseller ID</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="apple_reseller_id"
                                            name="apple_reseller_id"
                                            value={formData.apple_reseller_id}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-4">
                                        <div className="custom-control custom-checkbox checkbox-success">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="allowEnroll"
                                                name="can_enroll"
                                                checked={formData.can_enroll}
                                                onChange={handleChange}
                                            />
                                            <label className="custom-control-label" htmlFor="allowEnroll">
                                                Permitir enrolar (Reseller ID validado)
                                            </label>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Guardar cambios</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditReseller;
