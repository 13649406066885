import React, { useState } from 'react';
import './FileUploader.css'; // Asegúrate de importar el archivo CSS

const FileUploader = ({ onFileSelect, acceptedFileTypes }) => {
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [inputKey, setInputKey] = useState(Date.now()); // Para forzar el reinicio del input

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Obtener extensión del archivo
      const fileExtension = file.name.split('.').pop().toLowerCase();
      // Convertir la prop `acceptedFileTypes` en un array de extensiones válidas
      const validExtensions = acceptedFileTypes
        .replace(/\s/g, '') // Quitar espacios en blanco
        .split(',')
        .map(ext => ext.replace('.', '').toLowerCase()); // Quitar puntos y convertir a minúsculas

      if (validExtensions.includes(fileExtension)) {
        setFileName(file.name);
        setErrorMessage(''); // Limpiar cualquier mensaje de error
        onFileSelect(file); // Pasar el archivo seleccionado al componente padre
      } else {
        // Mostrar error si el archivo no es válido
        setFileName('');
        setErrorMessage(`Extensión de archivo no permitida. Solo se permiten: ${acceptedFileTypes}`);
        setInputKey(Date.now()); // Reiniciar el input
        onFileSelect(null); // Pasar null si no hay archivo válido seleccionado
      }
    } else {
      setFileName('');
      setErrorMessage('');
      setInputKey(Date.now()); // Reiniciar el input
      onFileSelect(null); // Pasar null si no hay archivo seleccionado
    }
  };

  return (
    <div className="custom-file-container">
      <label className="custom-file-container__custom-file">
        <input
          key={inputKey} // Cambia la clave del input para forzar su reinicio
          type="file"
          id="file-input"
          accept={acceptedFileTypes} // Tipo de archivo dinámico
          onChange={handleFileChange}
          className="custom-file-container__custom-file__custom-file-input"
        />
        <input
          type="text"
          value={fileName ? fileName : 'Haga click para seleccionar un archivo'}
          readOnly
          className="custom-file-container__custom-file__custom-file-control"
        />
        <span className="custom-file-container__custom-file__custom-file-control__button">
          Examinar
        </span>
      </label>
      {errorMessage && <p className="text-danger">{errorMessage}</p>} {/* Mensaje de error */}
    </div>
  );
};

export default FileUploader;
