import React, { useEffect, useState, useRef } from "react";
import { useHeader } from "../../../../contexts/HeaderContext";
import { Link,useNavigate } from "react-router-dom";
import apiClient from "../../../../api/apiClient";  // Importa el cliente de API
import './form-2.css';

function LostPassword() {
    const { setHeaderTitle } = useHeader();
    const formContentRef = useRef(null);
    const formImageRef = useRef(null);
    const [formData, setFormData] = useState({
        email: ''
    });
    const [error, setError] = useState(null); // Para manejar errores
    const [success, setSuccess] = useState(false); // Para manejar el éxito
    const navigate = useNavigate();

    useEffect(() => {
        setHeaderTitle("Plataforma de revendedores");

        if (formContentRef.current && formImageRef.current) {
            formImageRef.current.style.height = `${formContentRef.current.clientHeight}px`;
        }
    }, [setHeaderTitle]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.email !== '') {
            try {
                await resetPassword(formData);  // Llama a la función de restablecimiento de contraseña
                setSuccess(true); // Indica que la solicitud fue exitosa
                setError(null); // Oculta el mensaje de error
            } catch (error) {
                setError(error.response.data.message); // Muestra un mensaje de error
                setSuccess(false); // Oculta el mensaje de éxito
            }
        } else {
            setError('Por favor ingrese su correo electrónico.');
            setSuccess(false); // Oculta el mensaje de éxito
        }
    };

    const resetPassword = async (data) => {
        try {
            const response = await apiClient.post('/forgot-password', {
                email: data.email
            });
        } catch (error) {
            throw error;  // Re-lanzar el error para que se maneje en el `handleSubmit`
        }
    };

    return (
        <div className="row layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="widget widget-content-area br-4">
                    <div className="widget-one">
                        <h6>Restablecer contraseña</h6>
                        <br />
                        <div className="form-container outer">
                            <div className="form-form">
                                <div className="form-form-wrap" style={{ height: 'inherit' }}>
                                    <div className="form-container">
                                        <div className="form-content" ref={formContentRef}>
                                            {error && <div className="alert alert-danger">{error}</div>}
                                            {success && <div className="alert alert-success">Solicitud enviada con éxito. Verifique su correo.</div>}
                                            <form onSubmit={handleSubmit} className="text-left">
                                                <div className="form">
                                                    <div id="username-field" className="field-wrapper input">
                                                        <label htmlFor="email">Ingrese su correo electrónico</label>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                            <circle cx="12" cy="7" r="4"></circle>
                                                        </svg>
                                                        <input
                                                            tabIndex="500"
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            className="form-control"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>

                                                    <div className="d-sm-flex justify-content-between">
                                                        <div className="field-wrapper">
                                                            <button
                                                                tabIndex="502"
                                                                type="submit"
                                                                className="btn btn-info"
                                                                style={{ boxShadow: 'none', fontSize: '19px' }}
                                                                name="action"
                                                                value="user_login"
                                                            >
                                                                Solicitar restablecimiento
                                                            </button>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="hidden" name="callback" value="/index.php/" />
                                            </form>
                                        </div>
                                        <div className="form-image" ref={formImageRef}>
                                            {/* Aquí va el contenedor cuyo alto debe coincidir con el de form-content */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LostPassword;
