import React, { useEffect, useState } from "react";
import './TransactionDetail.css';
import apiClient from "../../../../api/apiClient";
import { useParams } from "react-router-dom";
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/adventure_time.css';
import { useHeader } from "../../../../contexts/HeaderContext";

const TransactionDetail = () => {
    const { setHeaderTitle } = useHeader();
    const { orderId, transactionId } = useParams();
    const [data, setData] = useState(null); // Cambié de [] a null para manejar el estado de carga.
    const [loading, setLoading] = useState(true); // Estado de carga

    useEffect(() => {
        setHeaderTitle("Detalle de transacción");
    }, [setHeaderTitle]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get(`/enrollments-transactions-logs/${transactionId}`);
                setData(response.data);
            } catch (error) {
                console.log("Error al obtener los datos. Por favor, inténtalo de nuevo más tarde.");
            } finally {
                setLoading(false); // Finaliza la carga después de obtener la respuesta.
            }
        };
        fetchData();
    }, [transactionId]);

    const safeParseJSON = (str) => {
        try {
            return JSON.parse(str);
        } catch (e) {
            console.error("Invalid JSON:", str);
            return { message: "Invalid JSON" };
        }
    };

    const getEndpointName = (endpoint) => {
        switch (endpoint) {
            case 'APPLE_BULK_ENROLL_DEVICES':
                return 'Bulk Enroll Devices API';
            case 'APPLE_CHECK_TRANSACTION_STATUS':
                return 'Check Transaction Status API';
            case 'SHOW_ORDER_DETAILS':
                return 'Show Order Details API'
            default:
                return endpoint;
        }
    };

    if (loading) {
        return <div>Cargando datos...</div>; // Indicador de carga mientras los datos son traídos de la API
    }

    if (!data) {
        return <div>Error al cargar los datos.</div>; // Manejo de errores si no hay datos
    }

    const firstTransaction = data[0];

    return (
        <div className="row layout-top-spacing">
            {/* Header con detalles generales de la primera transacción */}
            <div className="invoice-container col-lg-9 col-xl-9 col-xll-9">
                <div className="invoice-inbox">
                    <div id="ct">
                        <div className="invoice-00001">
                            <div className="content-section">
                                <div className="inv--note">
                                    <div className="row mt-4">
                                        <div className="col-sm-12 col-12 order-sm-0 order-1">
                                            <div className="d-flex">
                                                <div className="col-6">
                                                    <p className="p_status">
                                                        DEP Transaction ID
                                                        <br />
                                                        <span>{data.transaction?.dep_transaction_id}</span>
                                                    </p>
                                                </div>
                                                <div className="col-2">
                                                    <p className="p_status">
                                                        Tipo
                                                        <br />
                                                        <span>{data.transaction?.transaction_type}</span>
                                                    </p>
                                                </div>
                                                <div className="col-2">
                                                    <p className="p_status">
                                                        Resultado DEP
                                                        <br />
                                                        <span>{data.transaction?.dep_transaction_status || "En progreso"}</span>
                                                    </p>
                                                </div>
                                                <div className="col-2">
                                                    <p className="p_status">
                                                        Fecha Transacción
                                                        <br />
                                                        <span>{new Date(data.transaction?.dep_transaction_datetime).toLocaleDateString()}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Renderizado de las transacciones */}
            {data.log?.map((transaction, index) => {
                const requestData = safeParseJSON(transaction.request);
                const responseData = safeParseJSON(transaction.response);

                return (
                    <div key={index} className=" col-xl-9 col-xll-9 col-12 layout-spacing">
                        <div className="widget widget-content-area br-4 mt-3">
                            <div className="widget-one">
                                <div className="row mb-3">
                                    <div className="col-lg-3"></div>
                                    <div className="col-12">
                                        <div style={{ minHeight: "300px", overflow: "auto" }}>
                                            <h6 style={{ marginBottom: 0 }}>{getEndpointName(transaction.endpoint_name)}</h6>
                                            <p>{new Date(transaction.datetime).toLocaleString()}</p>
                                            <div className="d-flex mt-2">
                                                <div className="col-6 json-pretty-wrapper p-2">
                                                    <span>Petición</span>
                                                    <JSONPretty
                                                        data={requestData || { message: "No data" }}
                                                        className="json-pretty"
                                                    />
                                                </div>
                                                <div className="col-6 json-pretty-wrapper p-2">
                                                    <span>Respuesta</span>
                                                    <JSONPretty
                                                        data={responseData || { message: "No data" }}
                                                        className="json-pretty"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default TransactionDetail;
