import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../../../components/Table/Table";
import { Form, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useHeader } from "../../../../contexts/HeaderContext";
import apiClient from "../../../../api/apiClient";
import Swal from 'sweetalert2';

function NewEnrollment() {
    const { setHeaderTitle } = useHeader();
    const location = useLocation();
    const navigate = useNavigate();
    const selectedIds = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('selectedIds') ? queryParams.get('selectedIds').split(',') : [];
    }, [location.search]);

    const [formData, setFormData] = useState({
        customer_name: "",
        apple_organization_id: "",
        date: "",
        reseller_invoice_number: "",
        delivery_date: "",
        delivery_number: "",
    });

    const [formErrors, setFormErrors] = useState({});
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasFetchedData, setHasFetchedData] = useState(false);
    const [shouldFetchAdditionalData, setShouldFetchAdditionalData] = useState(false);

    useEffect(() => {
        setHeaderTitle("Enrolamientos");
    }, [setHeaderTitle]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [id]: value }));
    };

    useEffect(() => {
        const fetchDevices = async () => {
            if (selectedIds.length === 0 || hasFetchedData) {
                setLoading(false);
                return;
            }

            setLoading(true);
            try {
                const idsQueryParam = selectedIds.join(',');
                const response = await apiClient.get('/find-sales-record-item-pending-enrolled', {
                    params: {
                        sales_records_ids: idsQueryParam,
                    },
                });
                setData(response.data);
                setHasFetchedData(true);
                if (response.data.length === 0) {
                    setShouldFetchAdditionalData(true); // Solo si no hay resultados
                }
            } catch (error) {
                console.error("Error al obtener los dispositivos:", error.response.data.errors);
                setError("Hubo un error al obtener los dispositivos.");
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response.data.errors,
                }).then(() => {
                    navigate('/awaiting');
                });
            } finally {
                setLoading(false);
            }
        };

        fetchDevices();
    }, [selectedIds, hasFetchedData]);

    const renderTooltip = (message) => (
        <Tooltip id="button-tooltip" className="rounded">{message}</Tooltip>
    );

    const isFormValid = () => {
        let errors = {};
        const { customer_name, apple_organization_id, date, reseller_invoice_number, delivery_date, delivery_number } = formData;

        if (!customer_name) errors.customer_name = "Este campo es obligatorio.";
        if (!apple_organization_id) errors.apple_organization_id = "Este campo es obligatorio.";
        if (!date) errors.date = "Este campo es obligatorio.";
        if (!reseller_invoice_number) errors.reseller_invoice_number = "Este campo es obligatorio.";
        if (!delivery_date) errors.delivery_date = "Este campo es obligatorio.";
        if (!delivery_number) errors.delivery_number = "Este campo es obligatorio.";

        if (delivery_date && date && new Date(delivery_date) < new Date(date)) {
            errors.delivery_date = "La fecha de envío debe ser igual o posterior a la fecha de venta.";
        }

        if (selectedDevices.length === 0) {
            errors.selectedDevices = "Debe seleccionar al menos un dispositivo.";
        }

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Datos erróneos o faltantes',
                text: 'Por favor, verifique ingresar todos los datos y que la fecha de envío no sea anterior a la fecha de venta.',
            });
        }

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        if (!isFormValid()) return;

        const enrollmentData = {
            ...formData,
            ids_devices: selectedDevices.map(device => parseInt(device.id, 10)),
        };

        try {
            const response = await apiClient.post("/new-order-enrollment", enrollmentData);
            Swal.fire({
                icon: 'success',
                title: 'Solicitud enviada',
                text: 'Solicitud de enrolamiento enviada con éxito',
            });

            navigate(`/enrollments/${response.data.enrollment.id}`);
        } catch (error) {
            console.error("Error al enviar el enrolamiento", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `${error.response.data.error}`,
            });
        }
    };

    const handleNoResults = (noResults) => {
        if (noResults) {
            setShouldFetchAdditionalData(true);  // Solo dispara la búsqueda secundaria si no hay resultados
        } else {
            setShouldFetchAdditionalData(false);  // Resetea si ya hay resultados
        }
    };

    const fetchAdditionalDeviceData = async (filterText) => {
        try {
            const response = await apiClient.get(`/find-sales-record-item-by-serial?serial=${filterText}&company_id=${data[0]?.company_id}`);

            if (Array.isArray(response.data) && response.data.length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `El número de serie ${filterText} no existe.`,

                });
                return;
            }

            if (Array.isArray(response.data) && response.data.length === 1) {

                const dataDevice = response.data[0];

                if (dataDevice.serial.length !== filterText.length) return;

                if (dataDevice.enrolled === 1) {
                    console.log('')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `El número de serie ${dataDevice.serial} ya fue enrolado en la orden ${dataDevice.reseller_invoice_number} del cliente ${dataDevice.customer_name}.`,
                    });
                    return;
                }

                if (dataDevice.enrolled === 0) {
                    console.log('')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `El número de serie ${dataDevice.serial} pertenece a la factura de Startech ${dataDevice.invoice_number} que no fue seleccionada.`,
                    });
                    return;
                }
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "El número de serie indicado no existe.",
            });
        }
    };

    const headers = [
        { label: "Venta Startech", accessor: "invoice_number", searchable: true },
        { label: "SKU", accessor: "sku", searchable: true },
        { label: "Artículo", accessor: "description", searchable: true },
        { label: "Serie", accessor: "serial", searchable: true },
    ];

    // Mapa para convertir los IDs de los campos en nombres legibles para el usuario
    const fieldNames = {
        customer_name: "Razón social del cliente final",
        apple_organization_id: "Apple Organization ID del cliente final",
        date: "Fecha de la venta",
        reseller_invoice_number: "Número de comprobante de venta",
        delivery_date: "Fecha de envío",
        delivery_number: "Número de envío",
        selectedDevices: "Selección de dispositivos"
    };

    return (
        <div>
            <div className="row layout-top-spacing">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <h5 className="widget-title">Nueva orden de enrolamiento</h5>
                            <p>Complete los datos de la venta de {data[0]?.company_name} hacia el cliente final</p>
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="customer_name">
                                            <Form.Label>Razón social del cliente final</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.customer_name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {formErrors.customer_name && (
                                                <small className="text-danger">{formErrors.customer_name}</small>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="apple_organization_id">
                                            <Form.Label>Apple Organization ID del cliente final</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.apple_organization_id}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {formErrors.apple_organization_id && (
                                                <small className="text-danger">{formErrors.apple_organization_id}</small>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="date">
                                            <Form.Label>Fecha de la venta</Form.Label>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={renderTooltip(`La fecha de la venta corresponde a la fecha del comprobante de venta emitido por $${data[0]?.company_name} hacia su cliente final.`)}
                                            >
                                                <Form.Control
                                                    type="date"
                                                    value={formData.date}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </OverlayTrigger>
                                            {formErrors.date && (
                                                <small className="text-danger">{formErrors.date}</small>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group controlId="reseller_invoice_number">
                                            <Form.Label>Número de comprobante de venta</Form.Label>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={renderTooltip(`Ingrese el número comprobante de venta emitido por ${data[0]?.company_name}, que recibió el cliente que solicita el enrolamiento. Este número figurará en el Apple Business Manager del cliente.`)}
                                            >
                                                <Form.Control
                                                    type="text"
                                                    value={formData.reseller_invoice_number}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </OverlayTrigger>
                                            {formErrors.reseller_invoice_number && (
                                                <small className="text-danger">{formErrors.reseller_invoice_number}</small>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="delivery_date">
                                            <Form.Label>Fecha de envío</Form.Label>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={renderTooltip("La fecha de envío debe ser igual, o posterior, a la fecha del comprobante de venta. Nunca puede ser anterior a la fecha de la de venta.")}
                                            >
                                                <Form.Control
                                                    type="date"
                                                    value={formData.delivery_date}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </OverlayTrigger>
                                            {formErrors.delivery_date && (
                                                <small className="text-danger">{formErrors.delivery_date}</small>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group controlId="delivery_number">
                                            <Form.Label>Número de envío</Form.Label>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={renderTooltip("Ingrese el número de tracking provisto por el operador logístico. Si su cliente retira personalmente, ingrese el número de remito.")}
                                            >
                                                <Form.Control
                                                    type="text"
                                                    value={formData.delivery_number}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </OverlayTrigger>
                                            {formErrors.delivery_number && (
                                                <small className="text-danger">{formErrors.delivery_number}</small>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row layout-top-spacing">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <h5 className="widget-title">Seleccionar los dispositivos a enrolar</h5>
                            <p>Estos son los dispositivos que {data[0]?.company_name} adquirió en Startech y aún no fueron enrolados a ningún cliente</p>
                            <div className="alert alert-warning" role="alert">
                                Antes de comenzar a enrolar dispositivos de base instalada, recuerde comprobar que su cliente final todavía posee los dispositivos.
                                Solicite algún tipo de prueba que acredite la posesión de cada uno de los dispositivos a enrolar. No aplica para dispositivos nuevos.
                            </div>
                            <Table
                                data={data}
                                headers={headers}
                                enableCheckbox={true}
                                initialRowsPerPage={10}
                                onSelectedRowsChange={setSelectedDevices}
                                dataName={"productos"}
                                fetchAdditionalData={shouldFetchAdditionalData} // Pasa el estado de control
                                onNoResults={handleNoResults} // Indica al padre si hay resultados
                                onFetchAdditionalData={fetchAdditionalDeviceData} // Pasa la función para hacer la petición

                            />
                            {formErrors.selectedDevices && (
                                <small className="text-danger">{formErrors.selectedDevices}</small>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row layout-top-spacing">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <h5 className="widget-title">Enviar solicitud de enrolamiento</h5>
                            <p>Verifique los datos ingresados y presione el botón "Solicitar enrolamiento" para comenzar el proceso de enrolamiento</p>
                            <button className="btn btn-primary" onClick={handleSubmit}>Solicitar enrolamiento</button>
                            {/* Mostrar todos los errores al final */}
                            {Object.keys(formErrors).length > 0 && (
                                <div className="mt-4 alert alert-danger">
                                    <ul>
                                        {Object.entries(formErrors).map(([key, error]) => (
                                            <li key={key}>{`${fieldNames[key]} - ${error}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewEnrollment;
