import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Button } from 'react-bootstrap';
import './OrderActions.css';
import { useAuth } from '../../../../../../contexts/AuthProvider';
import OrderDetailsModal from "../Modals/ShowOrderDetailsAPI/ShowOrderDetailsAPI";
import Swal from "sweetalert2";
import apiClient from '../../../../../../api/apiClient';

const OrderActions = ({ orderStatus, orderType = "OR", remainingDevices, idOrder, actions }) => {

    const navigate = useNavigate();
    const { userInfo } = useAuth();
    const { read_level, execute, read } = userInfo.role || {};
    const isAdmin = execute && read;
    const isUser = !isAdmin;
    const [showModal, setShowModal] = useState(false);


    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    // Asegurarse de que orderStatus es un número
    const parsedOrderStatus = parseInt(orderStatus, 10);
    const readLevelInt = parseInt(read_level, 10);


    // Lógica para el estado de la orden
    const isOrderInProgress = parsedOrderStatus === null || parsedOrderStatus === undefined || parsedOrderStatus === 0;
    const isOrderCompleted = parsedOrderStatus === 1 || parsedOrderStatus === 2;
    const isOrderError = parsedOrderStatus === 3;

    // Lógica para el tipo de orden
    const isOR = orderType === "OR";
    const isRE = orderType === "RE";
    const isVD = orderType === "VD";
    const isOV = orderType === "OV";

    // Mostrar botón para detalles de la orden para Startech (read_level === 2) y solo si la orden no está en progreso
    const canShowDetailsButton = parseInt(read_level, 10) === 2 && !isOrderInProgress;

    // Mostrar opciones de dropdown basadas en el estado de la orden y tipo de orden para Startech
    const canShowDropdownOptions = isOrderCompleted;

    // Mostrar solo la opción "Retornar" para Revendedor si la orden está completada o completada con errores
    const canShowReturn = isOrderCompleted;

    // Estilos condicionales para las opciones deshabilitadas
    const disabledStyle = { textDecoration: 'line-through', color: '#999', pointerEvents: 'none' };
    const enabledStyle = { color: '#000' };

    // Lógica para deshabilitar "Retornar (RE)"
    const isReturnDisabled = isRE || !remainingDevices || !canShowReturn;

    const canModifyOV = actions.availableActions.canModifyOV;
    const canResend = actions.availableActions.canResend;
    const canVoidVD = actions.availableActions.canVoidVD;
    const canReturnRE = actions.availableActions.canReturnRE;

    const handleRedirect = (path) => {
        navigate(path);
    };

    const handleCancelOrder = async () => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "Esta acción anulará la orden. No podrás revertir este cambio.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, anularla',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            try {
                const response = await apiClient.post(`/void-enrollment-order/${idOrder}`);
                console.log(response);

                if (response.data.errors) {
                    Swal.fire('Error', response.data.errors, 'error');
                    return;
                }

                Swal.fire('Anulada', 'La orden ha sido anulada.', 'success').then(() => {
                    window.location.reload();
                });
            } catch (error) {
                Swal.fire('Error', 'Ocurrió un error al anular la orden.', 'error');
                console.error(error);
            }
        }
    };

    const handleResend = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "Esta acción reenviará la orden. No podrás revertir este cambio.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, reenviarla',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await apiClient.post(`/resend-enrollment-order/${idOrder}`);

                    if (response?.data?.error) {
                        Swal.fire('Error', response.data.error, 'error');
                    } else {
                        Swal.fire('Reenviada', 'La orden ha sido reenviada.', 'success');
                        window.location.reload();
                    }
                } catch (error) {
                    Swal.fire('Error', 'Ocurrió un error al reenviar la orden.', 'error');
                }
            }
        });
    };

    return (
        <>
            <div className="col-8 d-flex align-items-center justify-content-end p-0">
                <div className="d-flex align-items-center justify-content-end">
                    {/* Si es Startech y se puede mostrar el botón de detalles */}
                    {canShowDetailsButton && (
                        <>
                            <Button variant="outline-startech" onClick={handleShow}>
                                Mostrar detalles de la orden (API)
                            </Button>
                            <OrderDetailsModal show={showModal} handleClose={handleClose} idOrder={idOrder} />
                        </>
                    )}

                    {/* Dropdown de acciones solo para roles Admin, no para Usuario */}
                    {!isUser && (
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="btn-outline-startech"
                                id="dropdown-basic"
                                className="btn-outline-startech ml-2"
                            >
                                Acciones sobre la órden
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down">
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>

                                {(actions?.availableActions?.canModifyOV === true || actions?.showUnavailableActionsStrikethrough?.canModifyOV === true) && (
                                    <Dropdown.Item
                                        as="button"
                                        onClick={() => handleRedirect(`/enrollments/${idOrder}/override`)}
                                        disabled={!actions?.showUnavailableActionsStrikethrough?.canModifyOV}
                                        style={actions?.availableActions?.canModifyOV === true ? enabledStyle : disabledStyle}
                                    >
                                        <i className="flaticon-gear-fill mr-1"></i>
                                        <span style={!canModifyOV ? disabledStyle : {}}>
                                            Modificar (OV)
                                        </span>
                                    </Dropdown.Item>
                                )}

                                {(actions?.availableActions?.canVoidVD === true || actions?.showUnavailableActionsStrikethrough?.canVoidVD === true) && (
                                    <Dropdown.Item
                                        as="button"
                                        onClick={handleCancelOrder}
                                        disabled={!actions?.availableActions?.canVoidVD === true}
                                        style={actions?.availableActions?.canVoidVD === true ? enabledStyle : disabledStyle}
                                    >
                                        <i className="flaticon-gear-fill mr-1"></i>
                                        <span style={!canVoidVD ? disabledStyle : {}}>
                                            Anular (VD)
                                        </span>
                                    </Dropdown.Item>
                                )}

                                {(actions?.availableActions?.canReturnRE === true || actions?.showUnavailableActionsStrikethrough?.canReturnRE === true) && (
                                    <Dropdown.Item
                                        as="button"
                                        onClick={() => handleRedirect(`/enrollments/${idOrder}/return`)}
                                        disabled={!actions?.availableActions?.canReturnRE === true}
                                        style={actions?.availableActions?.canReturnRE === true ? enabledStyle : disabledStyle}
                                    >
                                        <i className="flaticon-bell-fill-2 mr-1"></i>
                                        <span style={!actions?.availableActions?.canReturnRE === true ? disabledStyle : {}}>
                                            Retornar (RE)
                                        </span>
                                    </Dropdown.Item>
                                )}

                                {(actions?.availableActions?.canResend === true || actions?.showUnavailableActionsStrikethrough?.canResend === true) && (
                                    <Dropdown.Item
                                        as="button"
                                        onClick={() => handleResend(`/enrollments/${idOrder}/override`)}
                                        style={enabledStyle}
                                    >
                                        <i className="flaticon-gear-fill mr-1"></i>
                                        <span >
                                            Reenvio
                                        </span>
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            </div>
        </>
    );
};

export default OrderActions;
