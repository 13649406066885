import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';  // Si tienes estilos personalizados
import Layout from './components/Layout/Layout';
import { AuthProvider } from './contexts/AuthProvider';
import { HeaderProvider } from './contexts/HeaderContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { LoadingProvider, LoadingContext } from './contexts/LoadingContext';
import Loader from './components/Loader/Loader';

function App() {
    const { isLoading } = useContext(LoadingContext);

    return (
        <AuthProvider>
            <Router>
                <SidebarProvider>
                    <HeaderProvider>
                        {isLoading && <Loader />} {/* Mostrar el loader si isLoading es true */}
                        <div className="App">
                            <Layout />
                        </div>
                    </HeaderProvider>
                </SidebarProvider>
            </Router>
        </AuthProvider>
    );
}

export default function RootApp() {
    return (
        <LoadingProvider>
            <App />
        </LoadingProvider>
    );
}
