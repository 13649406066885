import React from "react";

const OrderProducts = ({ products }) => {

    return (
        products.length > 0 ? (
            <div className="inv--product-table-section" style={{ padding: 0 }}>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <span style={{ color: "#000" }}>SKU</span>
                                </th>
                                <th scope="col">
                                    <span style={{ color: "#000" }}>Artículo</span>
                                </th>
                                <th scope="col">
                                    <span style={{ color: "#000" }}>Serie</span>
                                </th>
                                <th scope="col">
                                    <span style={{ color: "#000" }}>Estado</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {products && products.map((product, index) => (
                                <tr key={index}>
                                    <td>{product.sku}</td>
                                    <td>{product.description}</td>
                                    <td>{product.serial}</td>
                                    <td>
                                        {product.status === "COMPLETE"
                                            ? "Enrolado"
                                            : product.status === "UNENROLLING"
                                                ? "Desenrolando"
                                                : product.status === null
                                                    ? "Esperando respuesta"
                                                    : product.status}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        ) : (
            <div className="row">
                <div className="col ps-4">
                    <p><b>La orden ya no posee dispositivos enrolados.</b></p>
                </div>
            </div>
        )
    );
};

export default OrderProducts;