import React, { useEffect, useState, useRef } from "react";
import { useHeader } from "../../../../contexts/HeaderContext";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthProvider";
import apiClient from "../../../../api/apiClient";
import Swal from 'sweetalert2';

import './form-2.css';

function SetPassword() {
    const { setHeaderTitle } = useHeader();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const formContentRef = useRef(null);
    const [formData, setFormData] = useState({
        password: '',
        password_confirmation: ''
    });
    const [email, setEmail] = useState(''); // Estado para almacenar el email
    const { token } = useParams(); // Extrae el token de la URL
    const navigate = useNavigate();
    const { logout, login } = useAuth(); // Extraer la función de logout y auth del contexto de autenticación

    useEffect(() => {
        setHeaderTitle("Plataforma de revendedores");

        // Cerrar sesión automáticamente si hay una sesión activa
        logout();

        if (!token) {
            navigate('/login');
        }

        // Extraer el email del parámetro de la URL
        const params = new URLSearchParams(window.location.search);
        const emailParam = params.get('email');
        setEmail(emailParam); // Establecer el email en el estado
    }, [setHeaderTitle, token, navigate, logout]);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { password, password_confirmation } = formData;

        // Validación básica
        if (!password || !password_confirmation) {
            Swal.fire({
                icon: 'warning',
                title: 'Campos obligatorios',
                text: 'Ambos campos de contraseña son obligatorios.',
            });
            return;
        }

        if (password !== password_confirmation) {
            Swal.fire({
                icon: 'error',
                title: 'Error de validación',
                text: 'Las contraseñas no coinciden.',
            });
            return;
        }

        try {
            // Enviar al backend
            const response = await apiClient.post('/reset-password', {
                token,
                password,
                password_confirmation,
            });

            // Manejar la respuesta según sea necesario
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Contraseña actualizada',
                    text: 'Contraseña actualizada exitosamente.',
                }).then(async () => {
                    // Intentar el inicio de sesión automáticamente
                    try {
                        await login({ email, password });
                        navigate('/home'); // Redirigir a la página principal después del login
                    } catch (loginError) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al iniciar sesión',
                            text: loginError,
                        });
                    }
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al actualizar la contraseña.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Problema al procesar la solicitud',
                text: 'Hubo un problema al procesar la solicitud. Inténtalo de nuevo más tarde.',
            });
            console.error("Error en el reset-password:", error);
        }
    };

    return (
        <div className="row layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                <div className="widget widget-content-area br-4">
                    <div className="widget-one">
                        <h6>Bienvenido <b>{email}</b>. </h6> {/* Mostrar el email en la UI */}
                        <p className="mb-0 mt-3">Por favor, establezca una contraseña para poder acceder</p>
                        <br />
                        <div className="form-container outer">
                            <div className="form-form">
                                <div className="form-form-wrap" style={{ height: 'inherit' }}>
                                    <div className="form-container">
                                        <div className="form-content" ref={formContentRef}>
                                            <form onSubmit={handleSubmit} className="text-left">
                                                <div className="form">
                                                    <div id="password-field" className="field-wrapper input mb-2">
                                                        <div tabIndex="-1" className="d-flex justify-content-between">
                                                            <label tabIndex="-1" htmlFor="password">Ingrese su nueva contraseña</label>
                                                        </div>
                                                        <input
                                                            tabIndex="501"
                                                            id="password"
                                                            name="password"
                                                            type={passwordVisible ? "text" : "password"}
                                                            className="form-control"
                                                            value={formData.password}
                                                            onChange={handleInputChange}
                                                        />
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            id="toggle-password"
                                                            className="feather feather-eye"
                                                            onClick={togglePasswordVisibility}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                            <circle cx="12" cy="12" r="3"></circle>
                                                        </svg>
                                                    </div>
                                                    <div id="repassword-field" className="field-wrapper input mb-2">
                                                        <div tabIndex="-1" className="d-flex justify-content-between">
                                                            <label tabIndex="-1" htmlFor="password_confirmation">Repita su nueva contraseña</label>
                                                        </div>
                                                        <input
                                                            tabIndex="501"
                                                            id="password_confirmation"
                                                            name="password_confirmation"
                                                            type={passwordVisible ? "text" : "password"}
                                                            className="form-control"
                                                            value={formData.password_confirmation}
                                                            onChange={handleInputChange}
                                                        />
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            id="toggle-password"
                                                            className="feather feather-eye"
                                                            onClick={togglePasswordVisibility}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                            <circle cx="12" cy="12" r="3"></circle>
                                                        </svg>
                                                    </div>

                                                    <div className="d-sm-flex justify-content-between">
                                                        <div className="field-wrapper">
                                                            <button
                                                                tabIndex="502"
                                                                type="submit"
                                                                className="btn btn-info"
                                                                style={{ boxShadow: 'none', fontSize: '19px' }}
                                                                name="action"
                                                                value="user_login"
                                                            >
                                                                Establecer nueva contraseña
                                                            </button>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SetPassword;
