import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useHeader } from "../../../../contexts/HeaderContext";
import apiClient from '../../../../api/apiClient';
import RoleSelect from '../../../../components/Selects/RoleSelect/RoleSelect';
import Swal from 'sweetalert2';
import CompanySelect from "../../../../components/Selects/CompanySelect/CompanySelect";

const EditUserForm = () => {
  const { setHeaderTitle } = useHeader();
  const { id } = useParams();

  // State to store user information and the list of companies
  const [user, setUser] = useState({
    company_id: 1,
    full_name: '',
    email: '',
    password: '',
    role_id: 2,
    active: true,
  });
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [selectedReadLevel, setSelectedReadLevel] = useState(null);

  useEffect(() => {
    const fetchUserAndCompany = async () => {
      try {
        // Fetch user data
        const userResponse = await apiClient.get(`/users/${id}`);
        const userData = userResponse.data;

        // Fetch company list
        const companiesResponse = await apiClient.get('/companies');
        const companyList = companiesResponse.data.map(company => ({
          value: company.id,
          label: company.name,
          apple_ship_to_id: company.apple_ship_to_id,
        }));
        setCompanies(companyList);

        // Set user data
        setUser(userData);

        // Set selected read level based on company data
        const selectedCompany = companyList.find(company => company.value === userData.company_id);
        setSelectedReadLevel(selectedCompany && selectedCompany.apple_ship_to_id !== null ? 2 : 1);

      } catch (err) {
        setError("Error al obtener los datos. Por favor, inténtalo de nuevo más tarde.");
      }
    };

    fetchUserAndCompany();
  }, [id]);


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    if (name === "company_id") {
      const selectedCompany = selectedOption ? selectedOption.value : '';
      const selectedReadLevel = selectedOption && selectedOption.apple_ship_to_id !== null ? 2 : 1;

      setUser((prevData) => ({
        ...prevData,
        [name]: selectedCompany
      }));
      setSelectedReadLevel(selectedReadLevel);
    } else {
      setUser((prevData) => ({
        ...prevData,
        [name]: selectedOption ? selectedOption.value : ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await apiClient.put(`/users/${user.id}`, user);

      Swal.fire({
        icon: 'success',
        title: 'Usuario actualizado con éxito',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } catch (error) {
      setError('Error actualizando usuario.');
    }
  };

  const handleResetPassword = async () => {
    Swal.fire({
      title: 'Restablecer contraseña',
      text: '¿Estás seguro que deseas restablecer la contraseña de este usuario?, se enviará un correo electrónico con las instrucciones para restablecer la contraseña.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'approve-confirm-custom',
        cancelButton: 'approve-cancel-custom'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await apiClient.post('/forgot-password', { email: user.email });
          Swal.fire(
            'Solicitud enviada con éxito!',
            'Se ha enviado un correo electrónico con las instrucciones para restablecer la contraseña.',
            'success'
          );
        } catch (error) {
          Swal.fire(
            '¡Error!',
            'Hubo un problema al enviar la solicitud. Inténtalo de nuevo más tarde.',
            'error'
          );
        }
      }
    });
  };

  return (
    <div>
      <div className="row layout-top-spacing">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
          <div className="widget widget-content-area br-4">
            <div className="widget-one">
              <div className="col-xl-8 col-lg-9 col-md-12 col-12" style={{ paddingLeft: 0, marginBottom: 10 }}>
                <div className='d-flex justify-content-between'>
                  <h5>Editar Usuario</h5>
                </div>

                <br />

                <Col xs={12} md={8}>
                  {error && <Alert variant="danger">{error}</Alert>}
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formCompanyId">
                      <Form.Label>Empresa</Form.Label>
                      <CompanySelect
                        onChange={handleSelectChange}
                        value={companies.find(company => company.value === user.company_id) || null} // Find the company object in the list
                        name="company_id"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formFullName">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Ingrese nombre completo"
                        name="full_name"
                        value={user.full_name}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Ingrese email"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formRoleId">
                      <Form.Label>Rol</Form.Label>
                      <RoleSelect
                        onChange={handleSelectChange}
                        readLevel={selectedReadLevel}
                        value={user.role_id}
                        name="role_id"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formActive">
                      <Form.Check
                        type="checkbox"
                        label="Activo"
                        name="active"
                        checked={user.active}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Button variant="primary" className='mr-3' type="submit">
                      Guardar
                    </Button>
                    <Button variant="danger" className='m-3' type='button' onClick={handleResetPassword}>
                      Resetear contraseña
                    </Button>
                  </Form>
                </Col>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserForm;
