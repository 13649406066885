import React from 'react';
import './PageNotFound.css';  // Importamos los estilos desde un archivo CSS

const PageNotFound = () => {
    return (
        <div className="container-404">
            <div className="illustration">🚧</div>
            <h1>404</h1>
            <h2>Oops... Página no encontrada</h2>
            <p>Lo sentimos, la página que estás buscando no existe o ha sido movida.</p>
            <a href="/" className="home-link">Volver al inicio</a>
        </div>
    );
};

export default PageNotFound;
